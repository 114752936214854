<template>
  <div
    id="loginWz"
    class="index flex align-center"
    v-bind:style="{ backgroundImage: 'url(' + bgImg + ')' }"
  >
    <div class="title"></div>
    <div class="flex align-center login-wrap">
      <div class="login_section">
        <img class="left_bg_img" :src="applyLoginPic" alt="" />
        <div class="login_title">
          <div class="lgtitle">温州仲裁委员会仲裁员积分制管理系统</div>
        </div>
      </div>
      <div class="login_section flex align-center">
        <div class="tab-plan" id="qrCodeWrap">
          <div class="tab-plan-box" v-if="!wzc">
            <p class="tab-plan-title">扫码登录</p>
            <h4 class="tab-plan-text">
              使用“仲裁在线”小程序扫描下方二维码登录
            </h4>
            <el-image :src="qrCode" id="qrCode"></el-image>
            <!-- <img /> -->
            <span class="normaltab"
              >找不到小程序？<span class="tab-plan-tab" @click="clickTab(true)"
                >扫码打开</span
              ></span
            >
          </div>
          <div class="tab-plan-box" v-if="wzc">
            <p class="tab-plan-title">扫码打开</p>
            <h4 class="tab-plan-text">
              使用微信“扫一扫”扫描下方小程序码打开小程序
            </h4>
            <img :src="qrCode1" height="184" width="184" />
            <span class="normaltab"
              ><span class="tab-plan-tab" @click="clickTab(false)"
                >返回</span
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import { Client } from "../core/clients/client";
import { UiService } from "../core/services/ui.service";
import LoginService from "../core/services/login.service";
import UserModel from "../core/services/user.model";
import { UtilService } from "../core/services/util.service";
import { setStorageItem, getStorageItem } from "../core/clients/fn";
const util = new UtilService();
const ui = new UiService();
const client = new Client();
const loginService = new LoginService();
export default {
  name: "",
  components: {},
  props: {},
  // 定义属性
  data() {
    return {
      bgImg: "",
      applyLoginPic: require("@/assets/images/apply-login-pic.jpg"),
      qrCode: "",
      qrCode1: require("@/assets/images/eweima.jpg"),
      loading: "", //loading
      loginId: "",
      wzc: false, //是否是“仲裁在线”
      doc: "",
      checked: false, //是否选择“开发人员登录”
      count: 0, //记录轮询时间
      mpCodeCount: 0, //记录获二维码轮询次数
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.$nextTick(() => {
      sessionStorage.removeItem("token");
      this.clickTab(false);
      this.init();
    });
  },
  watch: {},
  methods: {
    clickTab(bool) {
      if (bool === this.wzc) {
        return;
      }
      this.wzc = bool;
      if (!this.wzc) {
        this.init();
      }
    },
    init() {
      sessionStorage.setItem("userInfo", "");
      this.loginId = util.randomString();
      let scan_id = this.loginId;
      localStorage.setItem("scan_id", scan_id);

      this.getScoreMpQRCode(scan_id);
    },
    async getScoreMpQRCode(scan_id) {
      let loading = ui.loading();
      let img = await loginService.getScoreMpQRCode(scan_id);
      loading.close();
      if (!img) {
        // 获取小程序码接口报错再次调用，连续5次报错则不再调用
        if (this.mpCodeCount < 5) {
          this.mpCodeCount += 1;
          await this.getScoreMpQRCode(scan_id);
        }
        return;
      }
      this.qrCode = img;
      this.getToken();
    },
    // 获取token
    async getToken() {
      sessionStorage.setItem("scan_id", this.loginId);
      let res = await loginService.scanLogin(this.loginId);
      if (res && res.token) {

        res.user_info.role = res.role;
        let result = await loginService.getUserInfo(res.user_info);
 
        if (result) {
          result.role = res.role || "";
          let user = new UserModel(result);
          sessionStorage.setItem("token", res.token);
          sessionStorage.setItem("user", user.name);
          sessionStorage.setItem("userInfo", JSON.stringify(user));
          ui.success("登录成功");
          if(this.$route.query.type=='rate'){
            this.$router.push({
              path:'/rate',
              query:{
                caseId:this.$route.query.caseId,
                arbiterId:this.$route.query.arbiterId
              }
            })
            return;
          }
          await this.$router.push("/home");


          return;
        }
        this.$myConfirm({
          message: "获取用户信息失败,将为您刷新，刷新后请重新扫码登录",
        })
          .then(() => {
            sessionStorage.removeItem("token");
            this.init();
          })
          .catch(() => {});

      } else {
        // 轮询获取token（5分钟内，2s轮询，5-15分钟内3s轮询，大于30分钟不再轮询）
        let time = 5 * 60 * 1000;
        let endTime = 30 * 60 * 1000;
        if(this.$route.path!='/login'){
          return;
        }
        if (this.count < time) {
          await util.sleep(2000);
          this.count += 2000;
          if (!this.wzc) {
            this.getToken();
          }
        } else if (time < this.count < endTime) {
          await util.sleep(5000);
          this.count += 3000;
          if (!this.wzc) {
            this.getToken();
          }
        }
      }
    },
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>

<style lang='scss' scoped>
#loginWz {
  height: 100vh;
  // padding: 0 250px;
  flex-direction: row-reverse;
  background-size: cover;
  background-position: center;
  justify-content: center;
  .title {
    position: absolute;
    top: 10%;
    left: calc(50% - 130px);
    width: 260px;
    text-align: center;
    img {
      width: 260px;
      height: auto;
    }
  }
  .help-box {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;

    .guide-title {
      height: 40px;
      line-height: 40px;
      justify-content: flex-end;
      padding-right: 10px;
    }

    .icon-shiyongshuoming {
      margin-right: 5px;
    }

    span {
      padding-right: 10px;
    }

    .guide-icon:after {
      position: absolute;
      top: 18px;
      right: -1px;
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #fff;
      border-bottom: 0;
    }
    .isActive:after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #fff;
      border-top: 0;
    }

    .guide-box {
      position: relative;
      z-index: 999;
      background: #ffffff;
      font-size: 16px;
      color: #666666;
      border-radius: 5px;
      padding: 10px 20px;
      margin-top: 6px;
    }
    li {
      height: 40px;
      line-height: 40px;
    }
    li:nth-child(1) {
      border-bottom: 1px solid #cacaca;
    }
    .icon-xiazai1 {
      color: #144bd7;
      margin-right: 15px;
    }
    a {
      color: #666666;
    }

    .guide-box:before {
      content: "";
      width: 0px;
      height: 0px;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #ffffff;
      position: absolute;
      top: 40px;
      left: 65%;
    }
  }
  .login-wrap {
    box-shadow: 0 8px 40px 0 rgba(117, 118, 119, 0.51);
    width: 100%;
    height: 100%;
  }
  .login_section {
    width: 50%;
    height: 100%;
    // width: 400px;
    // height: 600px;
    // width: 550px;
    background: white;
    // max-width: 590px;
    // max-height: 526px;
    // min-width: 400px;
    // min-height: 400px;
    flex-direction: column;
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center center;
    position: relative;
    justify-content: center;
    overflow: hidden;
    .left_bg_img {
      margin: 0 0;
      min-width: 100%;
      min-height: 100%;
      position: absolute;
      z-index: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .login_title {
      position: absolute;
      z-index: 1;
      top: 140px;
      left: 90px;
    }
    .lgtitle {
      font-size: 38px;
      font-family: "MiSans";
      font-weight: 800;
      color: #ffffff;
      // line-height: 24px;
      margin-bottom: 30px;
    }
    .lgtips {
      font-size: 20px;
      font-family: "PingFang SC";
      font-weight: 500;
      color: #ffffff;
      line-height: 24px;
      opacity: 0.6;
    }
    .el-button {
      height: 64px;
      width: 100%;
      background-color: #144bd7;
      border-radius: 6px;
      margin-top: 35px;
      font-size: 22px;
      color: #ffffff;
      cursor: pointer;
    }
    // temp--------------
    .login-btn {
      width: 100px;
      height: 40px;
      font-size: 16px;
    }

    .tab-plan {
      display: flex;
      flex-direction: column;
      align-items: center;
      // margin: 63px 0 0;
    }
    .tab-plan-title {
      color: #3c3c41;
      font-family: "MiSans";
      font-size: 32px;
      // line-height: 40px;
      font-weight: 800;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      margin-bottom: 30px;
    }
    .tab-plan-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      // width: 244px;
    }
    .tab-plan-text {
      color: #909399;
      font-size: 16px;
      // margin-top: 18px;
      // margin-bottom: 21px;
      font-family: "PingFang SC";
    }
    .normaltab {
      font-size: 16px;
      color: #909399;
      line-height: 24px;
    }
    .tab-plan-tab {
      // display: flex;
      // justify-content: center;
      // align-content: center;
      color: #1778ff;
      font-size: 16px;
      cursor: pointer;
      // span {
      //   border-bottom: 2px solid #a8aacb;
      //   line-height: 14px;
      // }
    }

    .tab-title {
      display: flex;
      width: 100%;
      height: 50px;
      align-items: center;
      justify-content: center;
    }

    .tab-title h3 {
      font-size: 18px;
      width: 50%;
      text-align: center;
      background-color: #f4f4f4;
      height: 100%;
      box-sizing: border-box;
      line-height: 50px;
      color: #999999;
    }

    .tab-title h3.active {
      border-bottom: 4px solid #144bd7;
      background-color: #ffffff;
      color: #333333;
    }
    .el-image {
      margin: 50px 0;
      height: 184px;
      width: 184px;
    }

    img {
      margin: 50px 0;
    }

    h4 {
      color: #999999;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
    }

    h3 {
      font-size: 26px;
      color: #333333;
      line-height: 24px;
    }

    // p:hover {
    //   cursor: pointer;
    // }

    p {
      font-size: 18px;
      line-height: 24px;
      color: #144bd7;
      margin-top: 21px;
      /*text-decoration: underline;*/
    }

    .user_input {
      margin-top: 53px;
    }

    .el-input {
      margin-bottom: 20px;
      height: 64px;

      i {
        font-size: 20px;
        color: #999999;
      }

      .el-input-group__prepend {
        background: #f4f4f4;
      }

      .el-input__inner {
        height: 64px;
        background: #f4f4f4;
        font-size: 18px;
      }
    }
  }
}
</style>