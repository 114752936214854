<template>
  <div class="head-nav header">
    <el-breadcrumb separator="/">
      <div v-if="breadcrumbList.length != 1">
        <el-breadcrumb-item
          v-for="(path, index) in breadcrumbList1"
          :key="index"
          :to="{path:path.pathto}"
          >{{ path.name }}</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ lastbreadcrumb.name }}</el-breadcrumb-item>
      </div>
      <div v-else>
        <el-breadcrumb-item
          v-for="(path, index) in breadcrumbList"
          :key="index"
          >{{ path.name }}</el-breadcrumb-item
        >
      </div>
    </el-breadcrumb>
    <div class="exit flex flex-direction-row">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icontouxiangnanhai"></use>
      </svg>
      <span class="username">{{ user || "" }}</span>
      <div class="exitbtn" @click="logOut()">
        <svg class="icon exiticon" aria-hidden="true">
          <use xlink:href="#icontuichu"></use>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { confirm } from "@/util/util";
export default {
  name: "head-nav",
  data() {
    return {
      user: [],
      list: [{}],
      breadcrumbList1: [],
      lastbreadcrumb: {},
    };
  },
  props: {
    breadcrumbList: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.getUser();
  },
  watch: {
    breadcrumbList: {
      handler: function () {
        if (this.breadcrumbList.length > 1) {
          let arr=[...this.breadcrumbList];
          this.lastbreadcrumb = arr.pop()
          this.breadcrumbList1 = arr;
        }
      },
    },
  },
  methods: {
    // 登录者信息
    getUser() {
      // let user = JSON.parse(sessionStorage.user);
      // this.user = user.name;
      this.user = sessionStorage.user;
    },
    setIndex(a) {
      this.$store.state.topIndex = a;
      sessionStorage.topIndex = a;
    },
    //点击退出清除信息
    logOut() {
      // confirm(this, "确定退出微法院签名？", () => {
      //   //清除用户信息
      //   localStorage.clear();
      //   sessionStorage.clear();
      //   this.$message.success("退出成功");

      //   this.$router.replace("/");
      // });
      this.$myConfirm({
        message:'确定退出登录吗？'
      }).then(()=>{
           localStorage.clear();
        sessionStorage.clear();
        this.$message.success("退出成功");

        this.$router.replace("/");
      }).catch(()=>{

      })
    },
  },
};
</script>

<style lang="scss" >
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  .exit {
    display: flex;
    align-items: center;
    height: 100%;
    .icon {
      width: 30px;
      height: 30px;
    }
    .exiticon {
      width: 16px;
      height: 14px;
      margin: 20px;
    }
    .username {
      line-height: 60px;
      height: 100%;
      display: inline-block;
      margin: 0 0 0 10px;
      padding-right: 20px;
      border-right: solid 1px #eeeeee;
    }
    .exitbtn {
      margin-right: -20px;
      cursor: pointer;
    }
  }
}
.el-breadcrumb__item {
  .is-link {
    color: #a9b1be !important;
    font-weight: normal !important;
  }
}
.el-breadcrumb__item:last-child {
  font-size: 18px;
  color: #38455d !important;
  line-height: 12px;
}
</style>
