import {Client} from '../clients/client'
import UserModel from './user.model';
import {  appCode } from '../clients/config';
const client = new Client();
export default class LoginService {
  constructor() {
    LoginService.instance = LoginService.instance || this;
    return LoginService.instance;
  }

  scan_id = '';
  // 获取token
  async getToken(scan_id) {
    let params = { scan_id,appCode };
    let res = await client.getToken(params);
    if (!res) {
      return;
    }
    return res;
  }
  // 轮询
  async scanLogin(scan_id) {
    let params = { scan_id };
    let res = await client.get('v1/users/scan_login', params, {verify:false});
    if (!res) {
      return;
    }
    return res;
  }
  // 点击登录
  async webLogin(params) {
    let res = await client.post('webLogin', params, {verify:true});
    if (!res) {
      return;
    }
    return res;
  }
  
  // 获取用户信息
  async getUserInfo(userInfo) {
    // let res = await client.getUserInfo();
    // if (!res) {
    //   return;
    // }
    let result = new UserModel(userInfo);
    return result;
  }

  // 新温仲仲裁员报名系统获取小程序码
  async getScoreMpQRCode(scan_id) {
    this.scan_id = scan_id;
    let scene = `t=evaluation-login&id=${scan_id}`;
    let data = {
      scene, // type=login   type=case  role=default
      page: 'pages/index/index'
    };
    let result = await client.get('v1/wechat/mp_qr_code', data, {verify:false});

    return result;
  }

  // // 更新统计信息
  // async updateStats(params) {
  //   let res = await client.updateStats(params);
  //   if (!res) {
  //     return;
  //   }
  //   return res;
  // }


}