import crypto from 'crypto-js';
import {getStorageItem, removeStorageItem} from './fn';

export function getToken(endpoint) {
	let token = getStorageItem('token');
	// let refreshToken = getStorageItem('refresh_token');
	if (!token) {
		return '';
	}
	// if (endpoint === 'refreshToken') {
	// 	return refreshToken;
	// }
	let parsedToken = parseToken(token);
	// token已过期
	if (parsedToken.exp * 1000 <= Date.now()) {
		removeStorageItem('token');
		// removeStorageItem('refresh_token');
		// removeStorageItem('token_valid_time');
		token = '';
	}
	return token;
}

export function parseToken(token) {
	let result;
	let slices = token.split('.');
	if (slices.length < 2) {
		return {};
	}
	let base64 = slices[1];
	let str = crypto.enc.Base64.parse(base64).toString(crypto.enc.Utf8);
	str = decodeURIComponent(str);
	try {
		result = JSON.parse(str);
	} catch (e) {
		result = {};
	}
	return result;
}
