import myConfirmDialog from './myConfirm'
function defaultCallBack(action) {
  if (!action) currentMsg.reject()
  currentMsg.resolve()
}
let currentMsg = null
const obj = {}

obj.install = function (Vue) {
  //1.创建组件构造器
  const ConfirmConstructor = Vue.extend(myConfirmDialog)

  //2.new的方式，根据组件构造器，可以创建出来一个组件对象
  const myConfirm = new ConfirmConstructor()

  //3.将组建对象，手动挂载到某一个元素上
  myConfirm.$mount(document.createElement('div'))
  // console.log(toast)
  //4.toast.$el对应的就是div
  document.body.appendChild(myConfirm.$el)
  Vue.prototype.callBack = defaultCallBack
  Vue.prototype.$myConfirm = (params) => {
    myConfirm.config(params)
    return new Promise((resolve, reject) => {
      currentMsg = { resolve, reject }
    })
  }
}

export default obj