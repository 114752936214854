import Vue from 'vue';
import VueRouter from 'vue-router';
import layout from '../components/home/layout'
import rate from "../components/home/form.vue"
import yaypRouter from '@/views/yayp/yayp.router'
import home from '@/views/Home';
import trainList from '@/views/trainList';
import trainDetail from '@/views/trainDetail';
import contributeList from '@/views/contributeList';
import contributeDetail from '@/views/contributeDetail';
import login from "@/views/login"
import { getToken } from '@/core/clients/token';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    redirect:"/login"
  },
  {
    path: '/login',
    name: 'Login',
    component:login
  },
  {
    path: '/layout',
    name: 'layout',
    meta:{
      breadcrumb:''
    },
    component: layout,
    // redirect: '/statistics/used',
    children: [
      yaypRouter,
      {
        path:'/home',
        component:home,
        meta:{
          breadcrumb:'首页'
        }
      },
      {
        path:'/trainList',
        component:trainList,
        meta:{
          breadcrumb:'培训学习评价统计'
        }
      },
      {
        path:'/trainDetail',
        component:trainDetail,
        meta:{
          breadcrumb:'培训学习评价统计/培训学习评价详情'
        }
      },
      {
        path:'/contributeList',
        component:contributeList,
        meta:{
          breadcrumb:'成绩贡献评价统计'
        }
      },
      {
        path:'/contributeDetail',
        component:contributeDetail,
        meta:{
          breadcrumb:'成绩贡献评价统计/成绩贡献评价详情'
        }
      },

  ]
  },
  {
    path:"/rate",
    name:"rate",
    component:rate
  },  
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});
// 解决出现相同路由时报错的问题
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};
router.beforeEach((to, from, next) => {
  if (to.path == '/login'||to.path == '/') {
    next();
    return;
  }
  let token = getToken();
  if (!token) {
      next('/login')
      return;
  }
  next();
});
export default router;
