import yayp from './yayp.vue';

import chiefArbitrator from "./chief/chiefArbitrator.vue"
import soleArbitrator from "./sole/soleArbitrator.vue"
import lines from './lines/lines.vue'
import chiefDetail from './chief/chief-detail.vue'
import linesDetail from './lines/lines-detail.vue'
import soleDetail from './sole/sole-detail.vue'
import general from './general.vue'
export default {
  path: '/yayp',
  component: yayp,
  children: [
    {
      path: 'general',
      component: general,
      meta: {
        breadcrumb: '一案一评总体评价'
      }
    },
    {
      path: 'chiefArbitrator',
      component: chiefArbitrator,
      meta: {
        breadcrumb: '首席仲裁员评价统计'
      }
    },
    {
      path: 'chiefDetail',
      component: chiefDetail,
      meta: {
        breadcrumb: '首席仲裁员评价统计/首席仲裁员评价详情'
      }
    },
    {
      path: 'soleArbitrator',
      component: soleArbitrator,
      meta: {
        breadcrumb: '独任仲裁员评价统计'
      }
    },
    {
      path: 'soleDetail',
      component: soleDetail,
      meta: {
        breadcrumb: '独任仲裁员评价统计/独任仲裁员评价详情'
      }
    },
    {
      path: 'lines',
      component: lines,
      meta: {
        breadcrumb: '边裁仲裁员评价统计'
      }
    },
    {
      path: 'linesDetail',
      component: linesDetail,
      meta: {
        breadcrumb: '边裁仲裁员评价统计/边裁仲裁员评价详情'
      }
    },
  ]
};
