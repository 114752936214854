const roleDecide = {
  dsr: '当事人',
  dlr: '代理人',
  zcy: '仲裁员',
  gzry: '工作人员',
};
class UserModel {
  constructor(data) {
    this.ac_id = data.ac_id;
    this.address = data.address;
    this.arbiter_id = data.arbiter_id;
    this.cert_no = data.cert_no;
    this.create_time = data.create_time;
    this.name = data.name;
    this.phone = data.phone;
    this.position = data.position;
    this.staff_id = data.staff_id;
    this.uid = data.uid;
    this.role = this.getRole(data.role);
    this.role_show = data.role;
    this.update_time = data.update_time;
    // eslint-disable-next-line no-underscore-dangle
    this._id = data._id;
  }
  getRole(role) {
    if (role) {
      return roleDecide[role] || '';
    }
    return '';
  }
}
export default UserModel;