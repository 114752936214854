<template>
  <div class="myConfirm">
    <el-dialog
      class="mydialog"
      :visible.sync="dialogVisible"
      width="30%"
      
      :show-close="false"
    >
      <div class="tips_header">
        <svg class="icon dialogicon" aria-hidden="true" style="fill: #f7a334">
          <use xlink:href="#icontishi"></use>
        </svg>
        <span class="aligncenter">提示</span>
      </div>
      <div class="tips_content">
        <span>{{ message }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" v-if="showcancel">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  name: "",
  components: {},
  // 定义属性
  data() {
    return {
      dialogVisible: false,
      message: "",
      showcancel: true,
    };
  },
  computed: {},
  watch: {},
  methods: {
    config({ message = "", showcancel = true }) {
      this.dialogVisible = true;
      this.message = message;
      this.showcancel = showcancel;
    },
    cancel() {
      this.dialogVisible = false;
      this.callBack(false);
    },
    confirm() {
      this.dialogVisible = false;
      this.callBack(true);
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>

<style lang='scss' scoped >
.mydialog {
  border-radius: 10px;
  min-height: 200px;
  ::v-deep.el-dialog {
    border-radius: 10px;
    min-height: 200px;
  }
  ::v-deep.el-dialog__body {
    padding: 20px 50px;
  }
  ::v-deep .el-dialog{
    margin-top: 15% !important;
  }
}

.tips_header {
  color: #38455d;
  font-size: 16px;
  margin-bottom: 13px;
}
.tips_content {
  font-size: 14px;
  color: #64728b;
  padding: 0 33px;
}
.dialogicon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  vertical-align: middle;
}
.aligncenter {
  vertical-align: middle;
}
.el-button {
  color: #64728b;
  border-color: #ebeef5;
}
.el-button:hover {
  background-color: #fff;
  border-color: #ebeef5;
}
.el-button:focus {
  background-color: #fff;
  border-color: #ebeef5;
}
.el-button--primary {
  color: #fff;
  background-color: #336ee6;
  border-color: #336ee6;
}
.el-button--primary:hover {
  background-color: #336ee6;
  border-color: #336ee6;
}
.el-button--primary:focus {
  background-color: #336ee6;
  border-color: #336ee6;
}
</style>