import {apiAppKey, apiAppSecret, apiStage} from './config';
import crypto from 'crypto-js';
import {generateUUID} from './fn';

export function buildHeaders(method, endpoint, params) {
	let headers = {};
	headers['x-ca-timestamp'] = Date.now();
	headers['x-ca-key'] = apiAppKey;
	headers['x-ca-nonce'] = generateUUID();
	headers['x-ca-stage'] = apiStage;
	headers['accept'] = 'application/json, text/plain, */*, application/json';
	headers['content-type'] = 'application/x-www-form-urlencoded';
	const signHeaderKeys = getSignHeaderKeys(headers);
	headers['x-ca-signature-headers'] = signHeaderKeys.join(',');
	const signedHeadersStr = getSignedHeadersString(signHeaderKeys, headers);
	const stringToSign = buildStringToSign(method, headers, signedHeadersStr, endpoint, params);
	headers['x-ca-signature'] = sign(stringToSign);
	return headers;
}

function getSignHeaderKeys(headers) {
	return Object.keys(headers).filter(key => key.startsWith('x-ca-')).sort();
}

function getSignedHeadersString(signHeaders, headers) {
	const list = [];
	for (let i = 0; i < signHeaders.length; i++) {
		const key = signHeaders[i];
		list.push(key + ':' + headers[key]);
	}
	return list.join('\n');
}

function buildStringToSign(method, headers, signedHeadersStr, endpoint, params) {
	let list = [method];
	let accept = headers['accept'];
	let contentMD5 = headers['content-md5'];
	let contentType = headers['content-type'];
	let date = headers['date'];
	list.push(accept, contentMD5, contentType, date);
	list.push(signedHeadersStr);
	list.push(getParsedUrl(endpoint, params));
	return list.join('\n');
}

function getParsedUrl(endpoint, params) {
	let res = `/${endpoint}`;
	let pairs = Object.keys(params).sort().map(key => {
		return params[key] === '' ? key : `${key}=${params[key]}`;
	});
	if (pairs.length) {
		res += `?${pairs.join('&')}`;
	}
	return res;
}

function sign(stringToSign) {
	return crypto.enc.Base64.stringify(crypto.HmacSHA256(stringToSign, apiAppSecret));
}
