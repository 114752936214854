import { apiHost, appCode } from './config';
import { request } from './request';
import { buildHeaders } from './headers';
import { getToken, parseToken } from './token';
import { setStorageItem } from './fn';

export class Client {
  onExpired; // token过期回调
  onFail; // 接口请求失败回调
  onSuccess; // 接口请求成功回调
  tokenValidTime = 7200; //s 过期时间 2小时
  constructor() {
    return (Client.instance = Client.instance || this);
  }

  get(endpoint, params, { fail, success, verify, host } = {}) {
    return this.request({ method: 'GET', endpoint, params, fail, success, verify, host });
  }

  post(endpoint, params, { fail, success, verify, host } = {}) {
    return this.request({ method: 'POST', endpoint, params, fail, success, verify, host });
  }

  async request({
    method,
    endpoint,
    params = {},
    host,
    fail = true,
    success = true,
    verify = true
  }) {
    host = host || apiHost;
    let headers = buildHeaders(method, endpoint, params);
  
    if (verify) {
      let token = getToken(endpoint);
      if (!token) {
        // token已过期 通过全局监听点击事件判断 跳转到登录页面
        this.onExpired();
        return;
      }
      // this.refreshToken(token);
      headers['Authorization'] = `Bearer ${token}`;
    } else {
      if (endpoint == 'refreshToken') {
        let token = getToken(endpoint);
        headers['Authorization'] = `Bearer ${token}`;
      } else {
        headers['Authorization'] = `appCode ${appCode}`;
      }
    }
    return request({
      method: method,
      url: `${host}/${endpoint}`,
      headers: headers,
      params
    })
      .then(res => {
        if (!res || res.code !== 0) {
          if (typeof this.onFail === 'function' && fail) {
            return Promise.reject(res.message || '请求失败, 请稍后重试');
          }
        }
        if (typeof this.onSuccess === 'function' && success) {
          this.onSuccess(endpoint, JSON.stringify(params));
        }
        return res.data;
      })
      .catch(errMsg => {
        if (typeof this.onFail === 'function') {
          this.onFail({ message: String(errMsg) });
        }
        return '';
      });
  }

  async refreshToken(token) {
    // let parsedToken = parseToken(token);
    // //parsedToken.exp过期时间戳s   /4是指在过期时间剩余1/4以内 重新获取token
    // if (parsedToken.exp * 1000 - this.tokenValidTime * 1000 / 4 <= Date.now()) {
    //   let res = await this.post(
    //     'refreshToken',
    //     {},
    //     {
    //       verify: false
    //     }
    //   );
    //   if (res) {
    //     token = res.token;
    //     setStorageItem('token', res.token);
    //   }
    // }
    // return token;
  }

  async getToken (params) {
		let token = getStorageItem('token');
		if (token) {
			return token;
		}
		let res = await this.get('public/getToken', params, {verify:false});
		if (res) {
			token = res.token;
			setStorageItem('token', res.token);
			setStorageItem('refresh_token', res.refresh_token);
			setStorageItem('token_valid_time', res.expires_in);
		}
		return res;
	}

  mock(ms = 500) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  setOnExpired(callback) {
    this.onExpired = callback;
  }

  setOnFail(callback) {
    this.onFail = callback;
  }

  setOnSuccess(callback) {
    this.onSuccess = callback;
  }
}
