<template>
  <div class="container">
    <keep-alive include="general,chiefArbitrator,lines,soleArbitrator">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "yayp",
  components: {},
  data() {
    return {};
  },
  async created() {},
  methods: {},
};
</script>

<style scoped></style>
