export class UtilService {
  constructor() {
    return (UtilService.instance = UtilService.instance || this);
  }

  removeEmoji(str) {
    return str.replace(
      /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/gi,
      ''
    );
  }

  verifyPhone(str) {
    return /^[0-9]{11}$/.test(str);
  }

  randomString() {
    return (Date.now() * 1000 + Math.floor(Math.random() * 1000)).toString(36);
  }

  getFileType(url) {
    url = url.toLowerCase();
    if (url.match('.jpg') || url.match('.png')) {
      return 'image';
    }
    if (url.match('.mp3')) {
      return 'audio';
    }
    if (url.match('.mp4')) {
      return 'video';
    }
    if (url.match('.pdf')) {
      return 'pdf';
    } else {
      return 'document';
    }
  }

  fileSuffix(type, name, useName = true) {
    let suffix = '';
    if (useName) {
      suffix = name.substring(name.lastIndexOf('.')).toLowerCase();
      suffix === '.docx' && (type = '');
    }
    switch (type) {
      case 'image/png':
      case 'image/jpeg':
      case 'image/tiff':
      case 'image/pjp':
      case 'image/gif':
      case 'image/svg':
      case 'image/bmp':
      case 'image/jpeg':
      case 'image/svgz':
      case 'image/webp':
      case 'image/ico':
      case 'image/xbm':
      case 'image/dib':
      case 'image/tif':
      case 'image/pjpeg':
      case 'image/avif':
      case 'image':
        return '.jpg';
      case 'mp4':
      case 'video/mp4':
        return '.mp4';
      case 'mp3':
      case 'audio/mp3':
        return '.mp3';
      case 'pdf':
      case 'application/pdf':
        return '.pdf';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return '.doc';
      default:
        return suffix;
    }
  }

  FileUrlAndType(files) {
    let urls = [];
    for (let item of files) {
      const url = URL.createObjectURL(item);
      const suffix = this.fileSuffix(item.type, item.name);
      const temp = {
        url,
        suffix
      };
      urls.push(temp);
    }
    return urls;
  }
  // 生成ID随机数
  randomString() {
    return (Date.now() * 1000 + Math.floor(Math.random() * 1000)).toString(36);
  };
  sleep(ms = 1000) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  };
  // 排序  type 1:正序  -1:倒序
  sortByKey(array, key, type = 1) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      if (type === 1) {
        return x < y ? -1 : x > y ? 1 : 0;
      } else {
        return x < y ? 1 : x > y ? -1 : 0;
      }
    });
  }

  // 判断服务器上的文件是否存在
  sExistsFile(url) {
    try {
      var xmlhttp;
      if (window.ActiveXObject) {
        xmlhttp = new ActiveXObject('Microsoft.XMLHTTP');
      } else if (window.XMLHttpRequest) {
        xmlhttp = new XMLHttpRequest();
      }
      xmlhttp.open('GET', url, false);
      xmlhttp.send();

      if (xmlhttp.readyState === 4) {
        if (xmlhttp.status === 200) {
          return true;
        } else if (xmlhttp.status === 404) {
          return false;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  }
}
