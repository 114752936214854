var numeral = require('numeral');
//扩展Date对象原型,添加日期格式化函数
/*使用方法
 * dateFormat(date,'yyyy-MM-dd hh:mm:ss')
 * */
const dateFormat = function(date, fmt) {
  //author: meizz
  var o = {
    'M+': date.getMonth() + 1, //月份
    'd+': date.getDate(), //日
    'h+': date.getHours(), //小时
    'm+': date.getMinutes(), //分
    's+': date.getSeconds(), //秒
    'q+': Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds() //毫秒
  };

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      );
    }
  }
  return fmt;
};

// 提示窗
const confirm = (self, content, success, fail) => {
  self
    .$confirm(content, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消'
    })
    .then(() => {
      success && success();
    })
    .catch(() => {
      fail && fail();
    });
};
//删除类型提示
const msgbox = function(self, content, type, success) {
  let h = self.$createElement;

  self
    .$msgbox({
      type: 'warning',
      message: h('p', { style: 'font-size:1.8rem;margin:3rem' }, [
        h('span', null, '是否删除" '),
        h('span', { style: 'color:#0496fd' }, content),
        h('span', null, type + '?')
      ]),
      showCancelButton: true,
      center: true,
      confirmButtonText: '删除',
      cancelButtonText: '取消'
    })
    .then(() => {
      success && success();
      // self.$message({
      //   type: 'success',
      //   message: '删除成功!'
      // });
    })
    .catch(() => {
      // self.$message({
      //   type: 'info',
      //   message: '删除失败!,请稍后重试'
      // });
    });
};
// 金额用10,000表示方法
const myNumeral = val => {
  if (val === parseInt(val, 10)) {
    return numeral(Number(val)).format('0,0');
  }
  return numeral(Number(val)).format('0,0.00'); // '10,000.00'
};

// 手机号码验证
const verifyPhone = num => {
  if (!num) {
    return false;
  }
  let phoneNum = removeAllSpace(num);
  let reg = /^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/; //验证规则，[34578]之间不需要|
  let flag = reg.test(phoneNum);
  return flag;
};
// 清除全部空格
const removeAllSpace = str => {
  return str.replace(/\s+/g, '');
};

export { dateFormat, confirm, msgbox, myNumeral, verifyPhone };
