<template>
  <div class="layout">
    <el-container>
      <el-aside width="240px">
        <side-nav></side-nav> 
        </el-aside>
      <el-container>
        <el-header height="60px">
          <head-nav :breadcrumbList="breadcrumbList"></head-nav>
          </el-header>
        <el-main>
          <el-card class="outer-content" v-if="$route.path!='/layout'">
            <keep-alive
        include="home,contributeList,trainList"
      >
          <router-view></router-view>
            </keep-alive>
          </el-card>
          <div v-if="$route.path=='/layout'" class="blank-default">
            <img src="../../assets/images/blank.png" alt="">
            <p class="welcometxt">欢迎登录温州仲裁委员会仲裁员积分制管理系统</p>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import sideNav from "./leftNav";
import headNav from "./headNav";
import {routerList} from './routerList'
export default {
  name: "layout",
  components: {
    "side-nav": sideNav,
    "head-nav": headNav,
  },
  // 定义属性
  data() {
    return {
      breadcrumbList:[]
    };
  },
  computed: {
     getbool() {
      //默认false
      return this.$store.state.welcomeBool;
    }
  },
  watch: {
    $route: {
      immediate:true,
      handler: function() {
        let currentPath = window.location.hash.slice(1);
        if (this.$route.path !== currentPath) {
          this.$router.push(currentPath);
        }
        this.getbreadcrumbList()
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    getbreadcrumbList(){
      // console.log(":",this.$route.meta.breadcrumb)
      if(!this.$route.meta.breadcrumb){
        return
      }
      let namelist = this.$route.meta.breadcrumb.split('/');
      let arr=[]
      for (let name of namelist) {
      let find = routerList.find((item) => item.name == name);
      if (find) {
        arr.push(find);
      }
    }
      this.breadcrumbList=arr;
      // console.log(this.$route)
      // console.log(this.breadcrumbList)

    }
  },
  created() {},
  mounted() {
   this.getbreadcrumbList()
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>

<style lang='scss' scoped>
.el-container {
  width: 100%;
  height: 100vh;
  .el-main {
    background: #f4f5f7;
  }
  .outer-content{
    height:calc(100vh - 105px);
    overflow-y:auto;
    position: relative;
  }
  .blank-default{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height:calc(100vh - 105px);
    img{
      width: 315px;
      height: 176px;
    }
  }
  .welcometxt{
    margin-top: 36px;
    font-size: 16px;
    color:#a9b1be;
  }
}

</style>