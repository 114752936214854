<template>
  <div class="side-nav" id="LeftNav">
    <div class="logo flex">
      <!-- <img class="logoimg" src="../../assets/images/org_logo.png" alt="" /> -->
      <span>温州仲裁委员会仲裁员积分制管理系统</span>
    </div>
    <!-- 左边导航 -->
    <el-menu
      :default-active="select"
      @open="handleOpen"
      background-color="#2c333d"
      text-color="#fff"
      :router="true"
      :unique-opened="true"
      :collapse="false"
    >
    <template v-for="(item, index) in navList">
      <el-submenu :key="index" :index="item.rid" v-if="item.multiple">
        <template slot="title">
          <svg class="icon" aria-hidden="true">
            <use :xlink:href="item.icon"></use>
          </svg>
          <span>{{ item.name }}</span>
        </template>
        <el-menu-item-group >
          <el-menu-item
            v-for="(value, index1) in item.children"
            :index="value.path"
            :key="index+'-'+index1"
            class="name"
          >
            {{ value.text }}
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-menu-item v-else
            :index="item.path"
            :key="index"
            class="name"
          >
          <svg class="icon" aria-hidden="true">
            <use :xlink:href="item.icon"></use>
          </svg>
            
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
    </template>
 

    </el-menu>
  </div>
</template>

<script>
import rightsService from "./rights.service";
import {routerList,selectList} from './routerList'
export default {
  data() {
    return {
      navList: rightsService.leftShowList,
      select: "", //选择的导航
    };
  },
  async created() {
    if (this.$route.path) {
      // console.log("path",this.$route)
      this.select = this.$route.fullPath;
      // this.select = '/manage/mechanism'
      this.getbreadcrumbList()
      return;
    }
  },
  mounted() {
    this.once();
  },
  methods: {
    getbreadcrumbList(){
      if(!this.$route.meta.breadcrumb){
        return
      }
      let namelist = this.$route.meta.breadcrumb.split('/');
      let arr=[]
      for (let name of namelist) {
      let find = routerList.find((item) => item.name == name);
      if (find) {
        arr.push(find);
      }
    }
      
      if(arr.length!=0){
        this.select=selectList[arr[0].pathto] || '';
      }
    },
    // 展开导航
    handleOpen(key) {
      for (let item of this.navList) {
        if (item.rid == key) {
          if (item.show == true) {  
            for (let value of item.children) {
              if (value.show == true) {
                this.select = value.path;
                this.$router.push(this.select);
                return;
              }
            }
          }
        }
      }
    },
    once() {
      if (sessionStorage.welBool == "true") {
        this.$store.state.welcomeBool = true;
      }
    },
    setWelcome() {
      this.$store.state.welcomeBool = false;
      sessionStorage.welBool = false;
    },
  },
  watch: {
    // 监测store.state
    "$store.state.adminleftnavnum": "select",
    $route: {
      async handler() {
        for (let data of this.navList) {
          if (data && data.children) {
            for (let item of data.children) {
              if (item.path == this.$route.path) {
                this.select = item.path;
                this.$store.commit("setPath", this.select);
              }
            }
          }
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="scss" >
// logo
#LeftNav {
  height: 100%;
  background: #2c333d;
  .el-menu {
    border-right: none;
    .el-menu-item {
      svg {
        fill: #788497;
      }
      color: #788497 !important;
    }
  .is-active.el-menu-item {
      // background-color: #336ee6 !important;
      color: #fff !important;
      svg {
        fill: #336ee6;
      }
    }
    .el-submenu__title {
      svg {
        fill: #788497;
      }
      color: #788497 !important;
    }
    .is-active .el-submenu__title {
      color: #fff !important;
      svg {
        fill: #336ee6;
      }
      i{
        color:#fff
      }
      
    }
    .el-submenu {
      .el-menu-item.is-active {
      background-color: #336ee6 !important;
      color: #fff !important;
      svg {
        fill: #fff;
      }
      
    }

    }
  }
    .icon {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
  .logo {
    height: 60px;
    display: flex;
    // padding: 0 1.2rem;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    align-items: center;
    background: #2c333d;
    color: #fff;
    padding: 0 20px;
    text-align: center;
    // span {
    //   margin-left: 8px;
    // }
  }
  .logoimg{
    width: 25px;
    height: 19px;
  }
}
</style>
