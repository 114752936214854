import Vue from 'vue';
import Vuex from 'vuex';
import state from './state.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: state,
  mutations: {
    setPath(state, path) {
      state.selectPath = path;
    }
  },
  actions: {},
  modules: {}
});
