import { Client } from '../core/clients/client';
import Vue from 'vue';
import { Loading } from 'element-ui';
const vue = new Vue();
const client = new Client();

class ApiClient {

  // 获取验证码
  /**
   * @param {String} phone 手机号
   */
  async getVerificationCode({ phone }) {
    let params = { phone };
    return await client.get('getVerificationCode', params, { verify: false });
  }
  async getCrbitrationCaseInfo(params){
    return await client.get('v1/score/case_arbiter_info', params, { verify: true });
  }
  async getArbiterScore(params){
    return await client.get('v1/score/info', params, { verify: true });
  }
  async addScore(params){
    return await client.post('v1/score/secretary', params, { verify: true });
  }
  async getCrbitrationScoreSum(params){
    return await client.get('v1/score_stat/case', params, { verify: true });
  }
    //V2为测试接口
  // async addScore(params){
  //   return await client.post('addScore', params, { verify: true });

  // }
  // async getCrbitrationScoreSum(params){
  //   return await client.get('getCrbitrationScoreSum', params, { verify: true });
  // }
  async getTotalList(params){
    return await client.get('v1/score_stat/evaluation', params, { verify: true });
  }
  async getCaseTotalList(params){
    return await client.get('v1/score_stat/case_all', params, { verify: true });
    
  }
  async getScoreDetail(params){
    return await client.get('v1/score_stat/case_detail', params, { verify: true });
  }
  async getTrainDetail(params){
    return await client.get('v1/score_stat/train_detail', params,{ verify: true });
  }
  async getTrainList(params){
    return await client.get('v1/score_stat/train', params,{ verify: true });
  }
  async getContributeList(params){
    return await client.get('v1/score_stat/contribute', params,{ verify: true });
  }
  async getContributeDetail(params){
    return await client.get('v1/score_stat/contribute_detail', params,{ verify: true });
  }
 
}

const apiClient = new ApiClient();
export default apiClient;
