class RightService {
  //左导航一级权限
  leftShowList = [
    {
      rid: 'A',
      name: '首页',
      icon: '#iconshouye',
      path: '/home',
      sids: ['A1'],
      show: false,
      multiple:false
    },
     {
      rid: 'B',
      name: '一案一评统计',
      icon: '#icontongji',
      path: '/yayp',
      sids: ['B1'],
      show: false,
      multiple:true,
      children: [
        {
          sid: 'B1',
          text: '总体评价',
          path: '/yayp/general',
          sids: ['B1'],
          show: false
        },
        {
          sid: 'B2',
          text: '首席仲裁员评价统计',
          path: '/yayp/chiefArbitrator',
          sids: ['B2'],
          show: false
        }, {
          sid: 'B3',
          text: '独任仲裁员评价统计',
          path: '/yayp/soleArbitrator',
          sids: ['B3'],
          show: false
        },{
          sid: 'B4',
          text: '边裁仲裁员评价统计',
          path: '/yayp/lines',
          sids: ['B4'],
          show: false
        }
      ]
    },
    {
      rid: 'C',
      name: '培训学习评价统计',
      icon: '#icontongji',
      path: '/trainList',
      sids: ['C1'],
      show: false,
      multiple:false
    },
    {
      rid: 'D',
      name: '成绩贡献评价统计',
      icon: '#icontongji',
      path: '/contributeList',
      sids: ['D1'],
      show: false,
      multiple:false
    },

  ];
}

const NrightService = new RightService();

export default NrightService;
