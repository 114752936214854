import {getPlatform} from './fn';

let platform = getPlatform();

export function request({method, url, headers, params}) {
	if (platform === 'wx') {
		return new Promise((resolve, reject) => {
			wx.request({
				method,
				url,
				header: headers,
				data: params,
				success(res) {
					if (res.statusCode === 200) {
						return resolve(res.data);
					}
					if (res.statusCode === 204) {
						return resolve({code: 200, data: {}});
					}
					reject('请求失败, 请稍后重试');
				},
				fail() {
					reject('请求失败, 请稍后重试');
				}
			});
		});
	}
	if (platform === 'dd') {
		return new Promise(resolve => {
			dd.httpRequest({
				method,
				url,
				headers,
				data: params,
				success(res) {
					resolve(res.data);
				},
				fail() {
					resolve();
				}
			});
		});
	}

	// 使用fetch api
	url = new URL(url);
	let searchParams = new URLSearchParams(params);
	let reqInit = {headers, method};
	if (method === 'GET') {
		url.search = searchParams;
	}
	if (method === 'POST') {
		reqInit.body = searchParams;
	}
	let req = new Request(url, reqInit);

	return fetch(req).then(res => {
		if (res.status === 200) {
			return res.json();  // {code: 200, data: {}}
		}
		if (res.status === 204) {
			return {code: 200, data: {}};
		}
		return Promise.reject('请求失败, 请稍后重试');
	});
}

export function upload({url, localPath, name, data}) {
	if (platform === 'h5') {
		let formData = new FormData();
		for (let [key, value] of Object.entries(data)) {
			formData.append(key, value);
		}
		formData.append('file', localPath);
		return fetch(url, {
			method: 'POST',
			body: formData
		}).then(res => {
			if (res.status === 200) {
				return res.json();;
			}
			if (res.status === 204) {
				return {code: 200, data: {}};
			}
			return Promise.reject('上传失败, 请稍后重试');
		});
	}
	return new Promise((resolve, reject) => {
		if (platform === 'wx') {
			wx.uploadFile({
				url: url,
				filePath: data.localPath,
				name: name,
				formData: data,
				success: res => {
					if (res && res.statusCode === 200) {
						resolve(`${url}/${data.key}`);
					} else {
						reject('上传失败, 请稍后重试');
					}
				},
				fail() {
					reject('上传失败, 请稍后重试');
				}
			});
		}
	});
}
