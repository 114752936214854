<template>
  <div id="sole">
    <div class="head">
      <div class="titlestyle">独任仲裁员评价列表</div>
      <span class="name">仲裁员姓名:</span>
      <el-input v-model="name" placeholder="请输入内容" @keyup.enter.native="getList"></el-input>
      <span class="name">年份:</span>
      <el-select v-model="value" placeholder="请选择">
        <el-option v-for="item in options" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
      <el-button type="primary" @click="getList" style="background:#144bd7;margin-left:20px">查询
      </el-button>
      <el-button @click="reset">重置</el-button>
    </div>
    <div style="margin-top:20px">
      <el-table :data="tableData" style="width: 100%;">
        <el-table-column prop="arbiter_name" label="仲裁员姓名" width="160px" fixed>
        </el-table-column>
        <el-table-column label="总评分" prop="sum_score" width="160px" sortable>
        </el-table-column>
        <el-table-column label="配合组庭" prop="cooperation" width="160px">
        </el-table-column>
        <el-table-column label="庭前准备" prop="preparation" width="160px">
        </el-table-column>
        <el-table-column label="庭审纪律" prop="trial_discipline" width="160px">
        </el-table-column>
        <el-table-column label="庭审质效">
          <el-table-column label="庭审驾驭能力较好" prop="trial_quality_1" width="160px">
          </el-table-column>
          <el-table-column label="做到“只开一次庭”" prop="trial_quality_3" width="160px">
          </el-table-column>
        </el-table-column>
        <el-table-column label="程序控制"  >
          <el-table-column label="调解和解时间不超过15日" prop="procedure_control_3" width="160px">
          </el-table-column>
          <el-table-column label="程序紧凑不拖沓" prop="procedure_control_4" width="160px">
          </el-table-column>
        </el-table-column>
        <el-table-column label="案件分析" prop="case_analysis" width="160px" >
        </el-table-column>
        <el-table-column label="文书质效">
          <el-table-column label="最后一次庭审之日起15日内向仲裁秘书报送文书" width="160px" prop="document_quality_7">
          </el-table-column>
          <el-table-column label="事实调查无遗漏" width="160px" prop="document_quality_2">
          </el-table-column>
          <el-table-column label="适用法律准确" width="160px" prop="document_quality_3">
          </el-table-column>
          <el-table-column label="证据认定完整" width="160px" prop="document_quality_4">
          </el-table-column>
          <el-table-column label="行文逻辑通顺或文字数字无误" width="160px" prop="document_quality_5">
          </el-table-column>
          <el-table-column label="不存在漏裁或超裁或错裁" width="160px" prop="document_quality_6">
          </el-table-column>
          <el-table-column label="对核阅意见认真对待" width="160px" prop="document_quality_7">
          </el-table-column>
        </el-table-column>
        <el-table-column label="审限期限" width="160px" prop="trial_deadline">
        </el-table-column>
        <el-table-column label="保密工作" width="160px" prop="secrecy">
        </el-table-column>
        <el-table-column label="操作" width="160px">
          <template slot-scope="scope" >
            <div class="toDetail" @click="toDetail(scope.row)">
              查看详情
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
import request from "../../../util/request";
import { UiService } from "@/core/services/ui.service";
const ui = new UiService();

let tempTableData;
export default {
  name: 'soleArbitrator',
  data() {
    return {
      name: "",
      tableData: [],
      options: [],
      value: "",
    };
  },
  watch: {
   
  },
  computed: {},
  async created() {
    this.getYearList();
    this.getList();

  },
  methods: {
    getYearList() {
      // 前十年
      const currentYear = new Date().getFullYear();
      const startYear = currentYear - 9;
      const endYear = currentYear;
      const pastDecade = Array.from({ length: 10 }, (_, i) =>
        String(startYear + i)
      );
      // 后十年
      const futureStartYear = currentYear + 1;
      const futureEndYear = currentYear + 10;
      const futureDecade = Array.from({ length: 10 }, (_, i) =>
        String(futureStartYear + i)
      );
      this.options = [...pastDecade, ...futureDecade];
      this.value=String(currentYear);
    },
    async getList(){
      let loading=ui.loading();
      let res = await request.getCrbitrationScoreSum({
        arbiter_role: "独任仲裁员",
        arbiter_name: this.name,
        years:this.value
      });
      loading.close();
      // this.options = res.year_list;
      // tempTableData=res.list;
      // let list=res.list.filter((res)=>{
      //  return res.arbiter_name.includes(this.name)
      // })
      this.tableData=res.list;
    },
    //重置
    reset() {
      this.name = "";
      this.value=String(new Date().getFullYear())
      // this.tableData = tempTableData;
      this.getList();
    },
    
    toDetail(e) {
      this.$router.push({
        path:`soleDetail`,
        query:{
          arbiter_id:e.arbiter_id,
          year:this.value
        }
      });
    },
  },
  watch: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
#sole {
  .head {
    .titlestyle {
      font-size: 16px;
      color: #4c4c4c;
      margin-bottom: 20px;
    }
    .titlestyle::before {
      content: "";
      width: 4px;
      height: 16px;
      background-color: #144bd7;
      display: inline-block;
      margin-right: 10px;
      position: relative;
      top: 2px;
    }
    .el-input {
      width: 350px;
      height: 40px;
      margin-right: 20px;
    }
    .name {
      font-size: 14px;
      margin-right: 10px;
    }
  }
  .toDetail {
    color: rgb(20, 75, 215);
    cursor: pointer;
  }

  ::v-deep {
    .el-table__cell {
      text-align: center;
    }
    .el-table thead.is-group th.el-table__cell {
      background: #f7f7f7;
    }
    .el-table__body-wrapper {
      max-height: calc(100vh - 368px);
      overflow: auto;
    }
    .el-table__fixed-body-wrapper {
      max-height: calc(100vh - 380px);
      overflow: auto;
    }
    .el-table__header {
      .cell {
        color: #4c4c4c;
      }
    }
  }
}
</style>
