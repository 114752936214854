<template>
  <div id="general">
    <div class="head">
      <div class="titlestyle">一案一评总体评价列表</div>
      <span class="name">仲裁员姓名:</span>
      <el-input v-model="name" placeholder="请输入内容" @keyup.enter.native="getList"></el-input>
      <span class="name">年份:</span>
      <el-select v-model="value" placeholder="请选择">
        <el-option v-for="item in options" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
      <el-button type="primary" @click="getList" style="background:#144bd7;margin-left:20px">查询
      </el-button>
      <el-button @click="reset">重置</el-button>
    </div>
    <div style="margin-top:20px">
      <el-table :data="tableData" style="width: 100%;" border>
        <el-table-column prop="arbiter_name" label="仲裁员姓名" width="160px" fixed>
        </el-table-column>
        <el-table-column label="一案一评总评分" prop="sum_score" sortable>

        </el-table-column>
        <el-table-column label="首席仲裁员总评分" prop="sx_score" sortable>

        </el-table-column>
        <el-table-column label="独任仲裁员总评分" prop="dr_score" sortable>

        </el-table-column>
        <el-table-column label="边裁总评分" prop="bc_score" sortable>

        </el-table-column>
        
      </el-table>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import request from "@/util/request";
import { UiService } from "@/core/services/ui.service";
const ui = new UiService();

let tempTableData;
export default {
  name: 'general',
  components: {},
  props: {},
  // 定义属性
  data() {
    return {
      name: "",
      tableData: [],
       options:[],
      value:""
    };
  },
  computed: {},
  created() {
    this.getYearList();
    this.getList();
  },
  mounted() {},
  watch: {},
  methods: {
    getYearList() {
      // 前十年
      const currentYear = new Date().getFullYear();
      const startYear = currentYear - 9;
      const endYear = currentYear;
      const pastDecade = Array.from({ length: 10 }, (_, i) =>
        String(startYear + i)
      );
      // 后十年
      const futureStartYear = currentYear + 1;
      const futureEndYear = currentYear + 10;
      const futureDecade = Array.from({ length: 10 }, (_, i) =>
        String(futureStartYear + i)
      );
      this.options = [...pastDecade, ...futureDecade];
      this.value=String(currentYear);
    },
    async getList(){
      let loading=ui.loading();
      let res = await request.getCaseTotalList({
        arbiter_name: this.name,
        years:this.value
      });
      loading.close();
      // this.options = res.year_list;
      // tempTableData=res.list;
      // let list=res.list.filter((res)=>{
      //  return res.arbiter_name.includes(this.name)
      // })
      this.tableData=res.list;

    },
    //重置
    reset() {
      this.name = "";
      this.value=String(new Date().getFullYear())
      // this.tableData = tempTableData;
      this.getList();
    },
    toDetail(e){
      console.log(e)
      // this.$router.push({path:`chief-detail?cert_no=${e.arbiter_id}`})
    }
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {}
};
</script>

<style lang='scss' scoped>
#general {
  .head {
    .titlestyle {
      font-size: 16px;
      color: #4c4c4c;
      margin-bottom: 20px;
    }
    .titlestyle::before {
      content: "";
      width: 4px;
      height: 16px;
      background-color: #144bd7;
      display: inline-block;
      margin-right: 10px;
      position: relative;
      top: 2px;
    }
    .el-input {
      width: 350px;
      height: 40px;
      margin-right: 20px;
    }
    .name {
      font-size: 14px;
      margin-right: 10px;
    }
  }
  .toDetail{
    color:rgb(20, 75, 215);
    cursor: pointer;
  }

  ::v-deep{
    .el-table__cell {
      text-align: center;
    }
    .el-table th.el-table__cell{
      background: #f7f7f7;

    }
    .el-table thead.is-group th.el-table__cell {
      background: #f7f7f7;
    }
    .el-table__body-wrapper {
      max-height: calc(100vh - 310px);
      overflow-y: auto;
    }
    .el-table__fixed-body-wrapper {
      max-height: calc(100vh - 310px);
      overflow-y: auto;
    }
    .el-table__header {
      .cell {
        color: #4c4c4c;
      }
    }
  }
}
</style>