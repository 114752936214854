let platform = getPlatform();

function generateUUID() {
	var d = Date.now();
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
	});
}

function getPlatform() {
	try {
		if (wx && wx.request) {
			return 'wx';
		}
		if (dd && dd.httpRequest) {
			return 'dd';
		}
	} catch (e) {
		return 'h5';
	}
}

function setStorageItem(key, value) {
	if (platform === 'wx') {
		return wx.setStorageSync(key, value);
	}
	if (platform === 'dd') {
		return dd.setStorageSync(key, value);
	}
	return sessionStorage.setItem(key, value);
}

function getStorageItem(key) {
	if (platform === 'wx') {
		return wx.getStorageSync(key);
	}
	if (platform === 'dd') {
		return dd.getStorageSync(key);
	}
	return sessionStorage.getItem(key);
}

function removeStorageItem(key) {
	if (platform === 'wx') {
		return wx.removeStorageSync(key);
	}
	if (platform === 'dd') {
		return dd.removeStorageSync(key);
	}
	return sessionStorage.removeItem(key);
}

export {
	generateUUID,
	getPlatform,
	setStorageItem,
	getStorageItem,
	removeStorageItem
}
