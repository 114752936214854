<template>
  <div id="rate">
    <div
      class="title"
      v-if="!subtitle"
      :style="!subtitle && redColor ? 'color:#ff0000' : ''"
    >
      {{ title }}:
    </div>
    <div class="content" :style="subtitle ? 'width: 100%' : ''">
      <div class="aa">
        <div class='text' v-if="subtitle" :style="redColor ? 'color:#ff0000' : ''">
          {{ subtitle }}
        </div>
        <div class="score">
          <!-- <div>{{good}}</div> -->
          <div
            v-for="(item, index) in num"
            :key="index"
            class="box"
            :class="scoreIndex === item ? 'active' : ''"
            @click="score(item)"
          >
            {{ item }}
          </div>
          <!-- <div class="bad">{{bad}}</div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "rate",
  props: {
    title: {
      type: String,
    },
    good: {
      type: String,
    },
    bad: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    data: {
      type: String,
    },
    info: {
      type: Object,
      default: () => {},
    },
    num: {
      type: Array,
      default: () => [0, 1, 2, 3, 4, 5],
    },
  },
  data() {
    return {
      // num: 6,
      scoreIndex: "",
      redColor: false,
    };
  },
  watch: {
    info: {
      handler: function () {
        if (this.info && this.info.status == 2) {
          this.scoreIndex = this.info[this.data];
        }
      },
      deep: true,
    },
  },
  methods: {
    score(index) {
      if(this.info.status==2){
        return;
      }
      this.scoreIndex = index;
      let data = this.data;
      this.$emit("score", { [data]: index });
      // console.log(index);
    },
    reset() {
      this.redColor = false;
      this.scoreIndex = "";
    },
    required(e) {
      console.log("点击提交时", e);
      if (this.scoreIndex === "") {
        this.redColor = true;
      } else {
        this.redColor = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
#rate {
  display: flex;
  justify-content: space-between;
  min-height: 56px;
  align-items: center;
  .title {
    width: 150px;
    // padding-left: 20px;
  }
  .content {
    .aa {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      .text{
        width: 40%;
        margin-right: 100px;
      }
      .score {
        display: flex;
        align-items: center;
        max-width: 480px;
    flex-wrap: wrap;
    padding-bottom: 10px;
        // flex-direction: column;
        .box {
          width: 30px;
          height: 28px;
          background: rgb(255, 255, 255);
          border: 1px solid #eeeeee;
          border-radius: 4px;
          line-height: 28px;
          color: #666;
          margin-left: 10px;
          text-align: center;
          cursor: pointer;
          margin-top: 10px;
        }
        .active {
          background: #144bd7;
          color: white;
        }
        .ratemes {
          display: flex;
          justify-content: space-between;
        }
        .bad {
          width: 60px;
          padding-left: 10px;
        }
      }
    }
  }
}
</style>