<template>
  <div id="contributeList">
    <div class="head">
      <div class="titlestyle">成绩贡献评价列表</div>
      <span class="name">仲裁员姓名:</span>
      <el-input v-model="name" placeholder="请输入内容" @keyup.enter.native="getList"></el-input>
      <span class="name">年份:</span>
      <el-select v-model="value" placeholder="请选择">
        <el-option v-for="item in options" :key="item" :label="item"
          :value="item">
        </el-option>
      </el-select>
      <el-button type="primary" @click="getList" style="background:#144bd7;margin-left:20px">查询</el-button>
      <el-button @click="reset">重置</el-button>
    </div>
    <div style="margin-top:20px">
      <el-table :data="tableData" style="width: 100%;" border>
        <el-table-column prop="arbiter_name" label="仲裁员姓名" width="160px" >
        </el-table-column>
        <el-table-column label="总评分" prop="sum_score"  sortable>
        </el-table-column>

          <el-table-column prop="a" label="首席仲裁员或独任仲裁员办案公正高效、文书制作精良、办结多起案件" >
            <template slot-scope="scope" >
            {{scope.row.a||0}}
          </template>
        </el-table-column>
          <el-table-column prop="b" label="积极宣传和推广仲裁制度，有效拓展案源，引导诚信签订仲裁协议，或其他显著成绩贡献">
            <template slot-scope="scope" >
            {{scope.row.b||0}}
          </template>
        </el-table-column>

        
        <el-table-column label="操作" width="160px">
          <template slot-scope="scope" >
            <div class="toDetail" @click="toDetail(scope.row)">
              查看详情
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
import request from "../util/request";
import { UiService } from "@/core/services/ui.service";
const ui = new UiService();

let tempTableData;

export default {
  name: 'contributeList',
  data() {
    return {
      name: "",
      tableData: [],
      options: [],
      value: "",
    };
  },
  watch: {

  },
  computed: {},
  async created() {
    this.getYearList();

    this.getList();



  },
  methods: {
    getYearList() {
      // 前十年
      const currentYear = new Date().getFullYear();
      const startYear = currentYear - 9;
      const endYear = currentYear;
      const pastDecade = Array.from({ length: 10 }, (_, i) =>
        String(startYear + i)
      );
      // 后十年
      const futureStartYear = currentYear + 1;
      const futureEndYear = currentYear + 10;
      const futureDecade = Array.from({ length: 10 }, (_, i) =>
        String(futureStartYear + i)
      );
      this.options = [...pastDecade, ...futureDecade];
      this.value=String(currentYear);
    },
    async getList(){
      let loading=ui.loading();
      let res = await request.getContributeList({
        arbiter_name:this.name,
        years:this.value
      });
      loading.close();
      // this.options = res.year_list;
      // tempTableData=res.list;
      // let list=res.list.filter((res)=>{
      //  return res.arbiter_name.includes(this.name)
      // })
      this.tableData=res.list;
    },
    //重置
    reset() {
      this.name = "";
      this.value=String(new Date().getFullYear())
      // this.tableData = tempTableData;
      this.getList();
    },

    toDetail(e){
      console.log(e)
      this.$router.push({
        path:`contributeDetail`,
        query:{
          arbiter_id:e.arbiter_id,
          year:this.value
        }
      })
    }
  },
  watch: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
#contributeList {
  .head {
    .titlestyle {
      font-size: 16px;
      color: #4c4c4c;
      margin-bottom: 20px;
    }
    .titlestyle::before {
      content: "";
      width: 4px;
      height: 16px;
      background-color: #144bd7;
      display: inline-block;
      margin-right: 10px;
      position: relative;
      top: 2px;
    }
    .el-input {
      width: 350px;
      height: 40px;
      margin-right: 20px;
    }
    .name {
      font-size: 14px;
      margin-right: 10px;
    }
  }
  .toDetail{
    color:rgb(20, 75, 215);
    cursor: pointer;
  }
  ::v-deep {
    .el-table__cell {
      text-align: center;
    }
    .el-table th.el-table__cell{
      background: #f7f7f7;

    }
    .el-table thead.is-group th.el-table__cell {
      background: #f7f7f7;
    }
    .el-table__body-wrapper {
      max-height: calc(100vh - 320px);
      overflow-y: auto;
    }
    .el-table__fixed-body-wrapper {
      max-height: calc(100vh - 320px);
      overflow-y: auto;
    }
    .el-table__header {
      .cell {
        color: #4c4c4c;
      }
    }
  }
}
</style>
