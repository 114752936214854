import Vue from 'vue';
import { Loading } from 'element-ui';

const vue = new Vue();

export class UiService {
  constructor() {
    return (UiService.instance = UiService.instance || this);
  }

  loading(text = '正在加载中...', target = 'document.body') {
    return Loading.service({ text, background: 'rgba(255,255,255,0.3)', target });
  }

  async warning(msg) {
    return vue.$message({
      message: msg,
      type: 'warning'
    });
  }

  async success(msg) {
    return vue.$message({
      message: msg,
      type: 'success'
    });
  }

  async error(msg) {
    return vue.$message({
      message: msg,
      type: 'error'
    });
  }

  alert(msg, title = '提示', type = 'info') {
    return vue.$alert(msg, title, {
      showCancelButton: false,
      type: type,
      showClose: false,
      dangerouslyUseHTMLString: true
    });
  }

  async confirm(msg, title = '提示', [confirmText, cancelText] = ['确定', '取消'], html = false) {
    return vue
      .$confirm(msg, title, {
        confirmButtonText: confirmText,
        cancelButtonText: cancelText,
        showClose: false,
        type: 'warning',
        dangerouslyUseHTMLString: html
      })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }
}
