<template>
  
  <router-view class="app"></router-view>

</template>

<script>
export default {
  name: 'app',
  components: {}
};
</script>

<style>
/* 重置样式 */
@import './assets/style/base.css';
/* 公共样式 */
@import './assets/style/global.css';
/* 引入字体 */
@import './assets/fonts/iconfont.css';
/* 重置Element样式 */
@import './assets/style/element.css';
</style>

<style lang="scss" >
.app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.container {
  /*border: 15px solid #f1f1f1;*/
  /*height: calc(100vh - 5.2rem);*/
  /*overflow-y: auto;*/
  /*box-sizing: border-box;*/
  //自定义弹窗样式
  /*.bc {*/
  /*  position: fixed;*/
  /*  top: 0;*/
  /*  right: 0;*/
  /*  bottom: 0;*/
  /*  left: 0;*/
  /*  overflow: auto;*/
  /*  margin: 0;*/
  /*  width: 100vw;*/
  /*  height: 100vh;*/
  /*  z-index: 20;*/
  /*  box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0.2);*/
  /*  background: rgba(0, 0, 0, 0.2);*/
  /*  display: flex;*/
  /*  justify-content: center;*/
  /*  align-items: center;*/
  /*  .bc-content {*/
  /*    width: 60% !important;*/
  /*    z-index: 2019;*/
  /*    padding: 2% 3%;*/
  /*    justify-content: center;*/
  /*    justify-items: center;*/
  /*    background-color: #fff;*/
  /*    // min-width: 25rem;*/
  /*    // max-width: 60% !important;*/
  /*    border-radius: 10px;*/
  /*    .bc-input {*/
  /*      padding: 0 1rem;*/
  /*    }*/
  /*    h1 {*/
  /*      font-size: 1.8rem;*/
  /*      font-weight: 600;*/
  /*      text-align: center;*/
  /*      margin: 1rem auto;*/
  /*    }*/
  /*    p {*/
  /*      margin: 1rem 0;*/
  /*    }*/
  /*    input {*/
  /*      height: 2.5rem;*/
  /*      margin: 1rem 0 4rem 0;*/
  /*    }*/
  /*    .bc-footer {*/
  /*      display: flex;*/
  /*      justify-content: space-between;*/
  /*      .el-button {*/
  /*        font-size: 1.4rem;*/
  /*        padding: 0.8rem 1rem;*/
  /*        border-radius: 3px;*/
  /*      }*/
  /*    }*/
  /*  }*/
  /*}*/
  /*::-webkit-scrollbar-thumb {*/
  /*  !*滚动条里面小方块*!*/
  /*  background: rgba(0, 0, 0, 0.2);*/
  /*}*/
  /*::-webkit-scrollbar-track {*/
  /*  !*滚动条里面轨道*!*/
  /*  background: rgba(0, 0, 0, 0.2);*/
  /*}*/
  /*.wrap {*/
  /*  width: 100%;*/
  /*  height: 100%;*/
  /*}*/
  /*//删除类型提示框样式*/
  /*.el-message-box__btns {*/
  /*  width: 70%;*/
  /*  display: flex;*/
  /*  flex-direction: row;*/
  /*  justify-content: space-between;*/
  /*  margin: 0 auto;*/
  /*}*/
  /*//提示框x号隐藏*/
  /*.el-message-box__close {*/
  /*  display: none;*/
  /*}*/
  /*//提示框样事调整*/
  /*.el-message-box__content {*/
  /*  p {*/
  /*    font-size: 1.7rem;*/
  /*  }*/
  /*}*/
  /*// element重置样式*/
  /*.el-message {*/
  /*  top: 7rem !important;*/
  /*}*/
  /*//会员列表页操作弹出框*/
  /*.el-popper[x-placement^='left'] {*/
  /*  margin: 0;*/
  /*}*/
  /*.el-popover {*/
  /*  width: 70px !important;*/
  /*  padding: 0;*/
  /*  width: auto;*/
  /*  min-width: 60px;*/
  /*  div {*/
  /*    width: 90px !important;*/
  /*    margin: 0 !important;*/
  /*    background-color: #fff;*/
  /*  }*/
  /*  .popper__arrow {*/
  /*    display: none;*/
  /*  }*/
  /*}*/
  /*// 列表字段超出显示提示框样式*/
  /*.el-tooltip__popper {*/
  /*  max-width: 300px;*/
  /*}*/
}
</style>
