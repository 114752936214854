let routerList=[
  {pathto:'home',name:'首页'},
  {pathto:'general',name:'一案一评总体评价'},
  {pathto:'chiefArbitrator',name:'首席仲裁员评价统计'},
  {pathto:'soleArbitrator',name:'独任仲裁员评价统计'},
  {pathto:'lines',name:'边裁仲裁员评价统计'},
  {pathto:'trainList',name:'培训学习评价统计'},
  {pathto:'contributeList',name:'成绩贡献评价统计'},
  {pathto:'chiefDetail',name:'首席仲裁员评价详情'},
  {pathto:'soleDetail',name:'独任仲裁员评价详情'},
  {pathto:'linesDetail',name:'边裁仲裁员评价详情'},
  {pathto:'trainDetail',name:'培训学习评价详情'},
  {pathto:'contributeDetail',name:'成绩贡献评价详情'},

 


]
let selectList={
  'general':'/yayp/general',
  'chiefArbitrator':'/yayp/chiefArbitrator',
  'soleArbitrator':'/yayp/soleArbitrator',
  'lines':'/yayp/lines',
  'home':'/home',
    'trainList':'/trainList',
    'contributeList':'/contributeList'
}
export{
  routerList,selectList
}